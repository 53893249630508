/** @jsx jsx */
import { jsx } from 'theme-ui'
import React, { useEffect } from 'react'
import { graphql, navigate } from 'gatsby'
import Layout from '~/components/Layout'
import Metadata from '~/components/Metadata'
import PressCoveragePage from '~/components/PressCoverage/Page'

const PressCoverage = ({
  data: {
    allContentfulPressCoverage: { edges }
  }
}) => {
  const coverage = edges.map(({ node }) => node)
  useEffect(() => {
    if (!coverage) {
      navigate('/')
    }
  }, [])

  return (
    <Layout breadcrumb={{ crumbLabel: 'Press' }}>
      {coverage && (
        <>
          <Metadata title="Press" />
          <PressCoveragePage coverage={coverage} />
        </>
      )}
    </Layout>
  )
}

export const query = graphql`
  query PressCoveragePage {
    allContentfulPressCoverage {
      edges {
        node {
          ...PressCoverageFragment
        }
      }
    }
  }
`

export default PressCoverage
