/** @jsx jsx */
import { jsx, Heading, Text, Grid, Container } from 'theme-ui'
import LocalInfiniteScroll from '~/components/Generic/LocalInfiniteScroll'
import { useScrollContext } from '../Generic/ScrollSnap'
import { useEffect } from 'react'
import ImgLoader from '../Generic/ImgLoader'

const PressCoveragePage = ({ coverage }) => {
  const { setSnaps } = useScrollContext()

  useEffect(() => {
    setSnaps(false)
    return () => setSnaps(true)
  }, [setSnaps])

  return (
    <Container>
      <Heading
        as="h1"
        variant="h1"
        sx={{
          fontWeight: 'medium',
          textTransform: 'uppercase',
          textAlign: 'center',
          mb: '2.2rem',
          letterSpacing: '2px'
        }}
      >
        Press
      </Heading>
      <Text
        as="p"
        sx={{ fontSize: '2.5rem', textAlign: 'center', mb: '7.8rem' }}
      >
        Click the icons to read about Super73 in the news
      </Text>
      <LocalInfiniteScroll
        items={coverage}
        renderItems={items => (
          <Grid
            columns={[1, 2, 3, 4]}
            sx={{
              justifyContent: 'center',
              alignItems: 'center',
              gap: ['1.5rem'],
              pb: '21.6rem',
              px: ['3rem', null, '6rem', '12rem']
            }}
          >
            {items.map(c => (
              <article key={c.id} sx={{ position: 'relative' }}>
                <a
                  href={c.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label={`Super73 on ${c.name} at the url ${c.url}`}
                  title={`Super73 on ${c.name}`}
                >
                  <ImgLoader
                    image={c.image}
                    alt={c.media.description ?? c.media.title}
                    bgColor="white"
                  />
                </a>
              </article>
            ))}
          </Grid>
        )}
      />
    </Container>
  )
}

export default PressCoveragePage
