/** @jsx jsx */
/* eslint-disable react-hooks/exhaustive-deps */
import { jsx } from 'theme-ui'
import { useState, useEffect } from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import PropTypes from 'prop-types'

const LocalInfiniteScroll = ({
  items,
  renderItems,
  options: { itemsPerPage = 4, threshold = 10 }
}) => {
  const totalPages = Math.ceil(items.length / itemsPerPage)
  const [renderedItems, setRenderedItems] = useState([])
  const [page, setPage] = useState(1)
  const [hasMoreItems, setHasMoreItems] = useState(true)

  useEffect(() => {
    if (page === totalPages) {
      setHasMoreItems(false)
      return
    }

    setRenderedItems(items.slice(0, page * itemsPerPage))
  }, [page])

  return (
    <InfiniteScroll
      loadMore={() => setPage(page + 1)}
      hasMore={hasMoreItems}
      threshold={threshold}
    >
      {renderItems(renderedItems)}
    </InfiniteScroll>
  )
}

LocalInfiniteScroll.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  renderItems: PropTypes.func.isRequired,
  options: PropTypes.shape({
    itemsPerPage: PropTypes.number,
    threshold: PropTypes.number
  })
}

LocalInfiniteScroll.defaultProps = {
  options: {
    itemsPerPage: 4,
    threshold: 10
  }
}

export default LocalInfiniteScroll
